import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const InvestorsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "investors-hero.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <main className="font-mulish w-full bg-gradient-to-r from-blue-900 to-blue-800 shadow-inner">
      <title>Conexum - Investors</title>
      <div>
        <header id="header" className="bg-black h-1/5 transition-all">
          <Navbar />
        </header>

        <div className="relative bg-gray-50">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
              <Img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                fluid={imageData}
                imgStyle={{
                  objectFit: "cover",
                  objectPosition: "68% 50%",
                }}
                alt="A Conexum founder doing a virtual meeting"
              />
            </div>
          </div>
          <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
            <div className="lg:pr-8 flex justify-center">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2 className="flex uppercase text-3xl gap-1 flex-col md:text-5xl font-bold text-blue-900 tracking-tighter">
                  <span>Co-Founders</span>
                  <span className="font-light tracking-tighter text-blue-400">
                    to investors
                  </span>
                </h2>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  We built Conexum fueled with motivation, with the desire to
                  create accessible technological solutions with a global reach
                  and high standards in Latin America. Our focus has always been
                  on improvement to provide a totally disruptive level of
                  service in the region.
                </p>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  Today we are a company in continuous growth that understands
                  and gets deeply involved in each client’s business providing
                  fair and precise solutions.
                </p>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  We created an organization that generates profits while always
                  prioritizing the well-being of our community.{" "}
                  <span className="font-bold">
                    We are motivated by the motivation
                  </span>{" "}
                  of committed, happy people who have the freedom to work in an
                  attractive environment.
                </p>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  We welcome those who believe in our project and share our
                  values. We greatly appreciate the knowledge they have about
                  the sector. More than just capital, we value investors who can
                  provide networking, know-how and contribute to the development
                  of new, innovative and disruptive solutions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default InvestorsPage;
